import { graphql } from 'gatsby';
import React, { useContext } from 'react';
import { AppContext } from '../context';
import DefaultPage from '../templates/default';

export default function fourOhFourPage(props) {
  let locale = 'en';
  if (props?.path?.includes('/ja-jp/')) {
    locale = 'ja-jp';
  }

  if (props?.path?.includes('/zh-cn/')) {
    locale = 'zh-cn';
  }

  const ctx = useContext(AppContext);

  if (locale === 'en' || ctx.locale === 'en') {
    return <DefaultPage {...props} />;
  }

  if (locale === 'ja-jp' || ctx.locale === 'ja-jp') {
    props.data.wpPage = props.data.ja;
    return <DefaultPage {...props} />;
  }

  if (locale === 'zh-cn' || ctx.locale === 'zh-cn') {
    props.data.wpPage = props.data.zhCn;
    return <DefaultPage {...props} />;
  }

}

export const pageQuery = graphql`
  query FourOhFour {
    wpPage(id: { eq: "cG9zdDoxNDY5" }) {
      ...SEO
      ...GeneratedWpPage
    }
    ja: wpPage(id: { eq: "cG9zdDoyMDMxOA==" }) {
      ...SEO
      ...GeneratedWpPage
    }
    zhCn: wpPage(id: { eq: "cG9zdDoyMDMzMg==" }) {
      ...SEO
      ...GeneratedWpPage
    }
    wp {
      ...GeneratedWp
    }
  }
`;
